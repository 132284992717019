

























import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterServices } from "@/services/master.service";
import { Component, Vue } from "vue-property-decorator";

export enum TRANSACTION_TYPE {
  WO = "Work Order",
  SO = "Sales Order",
  IC = "IC Change Unit",
}

const TransactionTypeSelectProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});

@Component
export default class TransactionTypeSelect extends TransactionTypeSelectProps {
  transactionTypes = [] as ResponseListMaster[];

  created(): void {
    this.getTransactionType();
  }

  getTransactionType(): void {
    let params = {
      name: "PICKING_TYPE",
    } as RequestQueryParamsModel;
    masterServices.listMaster(params).then((res: any) => {
      res.map((dataObject, index) => (dataObject.key = index));
      this.transactionTypes = res;
    });
  }

  handleInput(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
}
