var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        showSearch: true,
        disabled: _vm.disabled,
        value: _vm.value,
        "option-filter-prop": "children",
        "filter-option": _vm.filterOption,
        allowClear: true
      },
      on: { change: _vm.handleChange, input: _vm.handleInput }
    },
    _vm._l(_vm.transactionTypes, function(item, index) {
      return _c(
        "a-select-option",
        { key: index, attrs: { value: item.value } },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(item.value) + " ")
              ]),
              _vm._v(" " + _vm._s(item.value) + " ")
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }